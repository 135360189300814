.contact-section{
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.col-12 {
  flex: 1 0 100%;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button.theme-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button.theme-btn:hover {
  background-color: #0056b3;
}

