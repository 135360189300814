nav {
  background-color: #333;
  padding: 1em;
  overflow: auto;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  align-items: center;
}

nav ul li {
  margin: 0.5em 1em; /* Adjust margin to fit more items */
}

nav ul li {
  width: 50px;
  height: auto;
  margin-right: 1em;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

.logo-container {
  margin-right: 4em; /* Add some space after the logo */
}


nav img.logo {
  width: 100px;
  height: auto;
}
.usp-content {
  margin: 20px;
}

.usp-content h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.usp-content p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.usp-content strong {
  font-weight: bold;
}

