.pricing-page-section {
  padding: 40px 0;
}

.pricing-page-section h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.billing-info {
  font-size: 1.2em;
  color: #777;
}

.pricing-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  text-align: center;
}

.pricing-card.popular {
  border: 2px solid #007bff;
}

.pricing-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.price {
  font-size: 2.5em;
  margin: 20px 0;
}

.currency {
  font-size: 1em;
  vertical-align: super;
}

.amount {
  font-size: 2.5em;
}

.duration {
  font-size: 0.8em;
}

.features {
  list-style: none;
  padding: 0;
}

.features li {
  font-size: 1em;
  margin: 10px 0;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

