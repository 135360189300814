.home {
  padding: 20px;
}

.home-header {
  text-align: center;
  margin-bottom: 20px;
}

.home-header h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.home-content {
  max-width: 800px;
  margin: 0 auto;
}

.home-content h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.home-content p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
}
